@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;

}

img {
  max-width: 100%;
  height: auto;
}

body {
  background: url('../content/shootout.jpg') no-repeat center center fixed; 
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

.splash-tilt {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.splash-bg {
  height: 100%;
  width: 100%;
  background: rgb(93,107,253);
  background: linear-gradient(45deg, rgba(93,107,253,0.65) 0%, rgba(240,0,64,0.65) 100%);
}

.splash-logo {
  text-align: center;
}

.splash-logo img {
  margin-left: 9px;
  width: 400px;
}

.splash-pcount, h1, h2, h3 {
  font-family: 'Bebas Neue', cursive;
  color: #E2E2EE;
  text-shadow: 0px 0px 5px rgba(0,0,0,0.5);
  text-align: center;
  margin-top: 10px;
  letter-spacing: 2px;
}

.splash-content {
  display: flex;
  width: 410px;
  justify-content: space-between;
  align-items: center;
  margin: 25px auto 0 auto;
}

.splash-button {
}


.splash-button button {
  position: relative;
  width: 120px;
  padding: 15px 20px;
  background-color: #221D3F;
  color: white;
  border: none;
  box-shadow: 5px 5px 0px 0px #4A4E76;
  border-radius: 0 0 0 10px;
  font-family: 'Bebas Neue', cursive;
  font-size: 24px;
  cursor: pointer;
  letter-spacing: 2px;
  transition: 0.25s;
  /* border-left: 2.5px solid white;
  border-bottom: 2.5px solid white;
  border-top: 2.5px solid #B1B2D1;
  border-right: 2.5px solid #B1B2D1; */
  border: 3px solid white;
  box-sizing: border-box;
  overflow: hidden;
}

.splash-button button:hover {
  box-shadow: 0px 0px 0px 0px #4A4E76;
  transform: translate(5px, 5px)
}

.splash-button button:hover .sb-detail1,.splash-button button:hover .sb-detail2 {
  width: calc(100% + 1px);
}


.sb-detail1 {
  background-color: white;
  position: absolute;
  height: 4px;
  width: 40%;
  top: -1px;
  left: -1px;
  transition: 0.25s;
}

.sb-detail2 {
  background-color: white;
  position: absolute;
  height: 5px;
  width: 40%;
  bottom: -2px;
  right: -2px;
  transition: 0.25s;
}

@media screen and (max-width: 768px) {
  .splash-bg {
    height: 200%;
  }
  .splash-content {
    flex-direction: column;
    width: auto;
  }
  .splash-button {
    margin-bottom: 20px;
  }
  .splash-logo img {
    width: 200px;
  }
}

/* Police Light Codepen */
/* .splash-button span:first-child {
  animation: none;
}

.splash-button button:hover span:first-child {
  animation: red 0.5s linear infinite;
  animation-delay: 0.25s;
}

.splash-button button span:last-child {
  animation: none;
}

.splash-button button:hover span:last-child {
  animation: blue 0.5s linear infinite;
  animation-delay: 0.50s
}
@keyframes red {
  from {
    text-shadow: 0 0 5px red,
           0 0 15px red,
           0 0 30px red,
           0 0 60px red;
  }
}
@keyframes blue {
  from {
    text-shadow: 0 0 5px blue,
           0 0 15px blue,
           0 0 30px blue,
           0 0 60px blue;
  }
} */
